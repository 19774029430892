<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <polygon
      points="11.91 4.26 10.76 3.11 9.61 1.96 8.49 3.08 10.79 5.38 11.91 4.26"
      style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
    />
    <polygon
      points="7.48 4.09 1.27 10.3 .99 12.88 3.57 12.6 9.78 6.39 7.48 4.09"
      style="fill: none; stroke: currentColor; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
